import $ from 'jquery'
import FormDropzone from './components/dropzone'
import Validation from './components/validation'
import '../scss/main.scss'
import Grapnel from 'grapnel'
import shortid from 'shortid'

class SignaneForm {
  constructor() {
    this.form = '';
    this.forwardButton = 'button.forward'
    this.backButton = 'button.back'

    this.userDir = shortid.generate()
    this.step = '1'
    this.sidebarStep = '1'
    this.sidebar = 'order'
    this.nextStep = ''
    this.prevStep = '1'
    this.stepScan = []
    this.lastStep = ''
    this.formData = ''
    this.selectedElementImage = ''
    this.lastSelectionForm = ''

    this.initSelectBox()
    this.initSelectBoxStation()
    this.initNumberInput()
    this.initDropzoneUploadForFoilStep35()
    this.initDropzoneUploadForTouchscreenStep33()
    this.initDropzoneUploadForTouchscreenStep32()
    this.initDropzoneUploadForFoilStep31()
    this.initDropzoneUploadForDisplayStep31()
    this.initDropzoneUploadForDisplayStep32()
    this.displayAndereAnsprechpartnerOnContactForm()
    this.setMinDateForDatepicker()

    this.initSelectOrderItem()
    this.initClickOnForward()
    this.initClickOnBack()
    this.initClickOnNextOrderButton()

    $('input[name="user_dir"]').attr('value', this.userDir)

    this.router = new Grapnel

    this.router.get('form/:step/:sidebar', req => {
      this.step = req.params.step;
      this.sidebar = req.params.sidebar
      this.sidebarStep = this.step.length >= 2 ? this.step.substring(0,1) : this.step

      const forms = $('div.order-form-items')

      forms.each((index, object) => {
        $(object).find('input').each((index, input) => {
          $(input).attr('data-fetch-step', $(object).attr('data-step'))
        })
      })

      const $clickedElementGroup = $(`div.order-form-items[data-step="${this.step}"]`)

      if (this.sidebarStep <= '3'
        && $clickedElementGroup.attr('data-next-step') // Order Details step
      ) {
        this.nextStep = $clickedElementGroup.attr('data-next-step')
        this.prevStep = $clickedElementGroup.attr('data-prev-step')
        this.sidebar = $clickedElementGroup.attr('data-sidebar')
      } else if (this.sidebarStep === '4') { // Contact Form Step
        const $contactForm = $(`.contact-form`)
        this.nextStep = $contactForm.attr('data-next-step')
        this.sidebar = $contactForm.attr('data-sidebar')
        if ( $contactForm.attr('data-prev-step').length === 0) {
          this.prevStep = $contactForm.attr('data-prev-step')
        } else {
          this.prevStep = this.lastSelectionForm
        }
      } else if (this.sidebarStep === '5') {
        const $orderOverview = $(`.overview`)
        this.nextStep = $orderOverview.attr('data-next-step')
        this.sidebar = $orderOverview.attr('data-sidebar')
        this.prevStep = $orderOverview.attr('data-prev-step')
      } else {
        this.prevStep = $clickedElementGroup.attr('data-prev-step')
      }
      // Scan for filled out document
      let stepData = []
      if (this.stepScan.length > 0) {
        stepData = this.stepScan.filter(el => {
          return el.step === this.step
        })[0]
      }
      if (typeof stepData !== "undefined" && Object.keys(stepData).length > 0) {
        this.nextStep = stepData.nextStep
      }

      this.setHeadlineBarSteps()
      this.checkButtons()
      this.displayStep(this.step)

      this.setStatusToSidebarElement(this.sidebar, this.sidebarStep, true, false)

      if (!$(`div.sidebar[data-meta="${this.sidebar}"]`).hasClass('show-sidebar')) {
        this.displaySidebar(this.sidebar)
      }
    });
  }

  displayAndereAnsprechpartnerOnContactForm() {
    $(document).on('click', `.contact-form label.checkbox`, (el) => {
      const $el = $(el.currentTarget)
      const $ansprechpartner = $('.anderer_ansprechpartner')

      if ($el.find('input').is(':checked')) {
        $ansprechpartner.addClass('active').find('input').addClass('required')
      } else {
        $ansprechpartner.removeClass('active').find('input').removeClass('required')
      }

    })
  }

  setHeadlineBarSteps() {
    const $headlineSteps = $('.headline-steps__step')
    $headlineSteps.removeClass('active')
    for(let i = 1; i <= this.sidebarStep; i++) {
      $(`.headline-steps__step[data-sidebar-step="${i}"]`).addClass('active')
    }
  }

  displayStep(step) {
    $('div.order-form-items, div.contact-form, div.form-radio, div.overview, div.sendmail').removeClass('show-step')
    const $formStep = $(`div[data-step="${step}"]`).addClass('show-step').find('input').attr('data-form-step', step)
  }

  displaySidebar(sidebar) {
    $('div.sidebar').removeClass('show-sidebar')
    const $sideBar = $(`div.sidebar[data-meta="${sidebar}"]`).addClass('show-sidebar')
  }

  setStatusToSidebarElement(sidebar, step, active = false, selected = false, forceDeselect = false) {
    const $sideBar = $(`div.sidebar[data-meta="${sidebar}"]`)

    for (let i = 1; i <= step; i++) {
      const $sidebarStep = $sideBar.find(`div.sidebar__step[data-step="${i}"]`);

      if (active) {
        $sidebarStep.addClass('active')
      } else {
        $sidebarStep.removeClass('active')
      }
      if ( !forceDeselect) {
        if (selected || $sidebarStep.hasClass('selected')) {
          $sidebarStep.addClass('selected')
        } else {
          $sidebarStep.removeClass('selected')
        }
      } else {
        if (selected) {
          $sidebarStep.addClass('selected')
        } else {
          $sidebarStep.removeClass('selected')
        }
      }
    }
  }

  deleteOrderDataFromStep() {
    const $clickedElementGroup = $(`div.order-form-items[data-step="${this.step}"]`)
    const $sideBar = $(`.sidebar[data-meta="${this.sidebar}"] .sidebar__step[data-step="${this.sidebarStep}"]`)
    $clickedElementGroup.find('.order-form-items__item').removeClass('active')
    $clickedElementGroup.find('input[type="radio"]').prop('checked', false)
    $sideBar.removeClass('selected active')
    $sideBar.find(`.sidebar__step-selection`).html('')
  }

  initClickOnForward() {
    $(document).on('click', this.forwardButton, button => {
      $('html, body').animate({
          scrollTop: $('body').offset().top - 50
        },'fast');

      $('.warning-box').removeClass('active').find('ul').html('') // remove warning box
      if (this.sidebarStep === '3') {
        const DetailForm = new Validation({
          errorContainer: '.warning-box',
          withCustomSelectBoxes: true,
        });
        DetailForm.clearErrorContainer()
        const inputErrors = DetailForm.checkRequired($('form'), false);

        this.lastSelectionForm = this.step
        if (inputErrors.length > 0) { // Set greater than 0 for prod
          DetailForm.putErrorsOnInputField(inputErrors)
        } else {
          this.sidebar = 'contact-form'
          this.router.navigate(`form/${this.nextStep}/${this.sidebar}`)
        }

      } else if (this.sidebarStep === '4') {
        const contactFormValidation = new Validation({
          errorContainer: '.warning-box',
          withCustomSelectBoxes: true,
        })
        contactFormValidation.clearErrorContainer()
        const inputErrorsContactForm = contactFormValidation.checkRequired($('form'), false);
        if (inputErrorsContactForm.length > 0) { // set greater than 0 for prod
          contactFormValidation.putErrorsOnInputField(inputErrorsContactForm)
        } else {
          this.formData = $('form').serializeArray()

          let sequelizeFormData = []
          this.formData.forEach(element => {
            if(element.value !== "") {
              sequelizeFormData.push({name: element.name, value: element.value})
            }
          })
          this.formData = sequelizeFormData
          console.log(this.formData)

          this.gatherAndDisplayFormDataToOverview()
          this.nextStep = 5;
          this.sidebar = 'overview'
          this.router.navigate(`form/${this.nextStep}/${this.sidebar}`)
        }
      } else if (this.sidebarStep === '5') {
        /* @TODO SEND THE MAAAAAIL */
        const isCheckBoxRequired = $('input[name="order_is_correct"]').is(':checked')
        const $warningBox = $('.overview .warning-box')
        if (!isCheckBoxRequired) {
          $warningBox.addClass('active').find('ul').html('<li>Bitte bestätigen Sie Ihre Bestellung bevor Sie diese versenden.</li>')
        } else {
          $warningBox.removeClass('active').find('ul').html('')

          $.ajax({
            url: '/sendmail.php',
            method: 'POST',
            data: {
              action: 'sendmail',
              csrf: 'aXYXmQThcBUuK0R231Fa6tfgDQDw3U7r',
              data: this.formData
            },
            success: (response) => {

            }
          })

          this.nextStep = 6;
          this.sidebar = 'sendmail'
          this.router.navigate(`form/${this.nextStep}/${this.sidebar}`)
        }
      } else {
        this.router.navigate(`form/${this.nextStep}/${this.sidebar}`)
      }
    })
  }

  initClickOnBack() {
    $(document).on('click', this.backButton, () => {
      this.deleteOrderDataFromStep()
      this.prevStep = this.prevStep.length > 0 ? this.prevStep : this.lastSelectionForm
      this.router.navigate(`form/${this.prevStep}/${this.sidebar}`)
    })
  }

  initClickOnNextOrderButton() {
    $(document).on('click', 'button.back-to-start', (el) => {
      el.preventDefault()
      $('form input').each((key, input) => {
        const $input = $(input)
        if ( $input.attr('type') === 'number') {
          $input.val(0)
        } else if ( $input.attr('type') === 'text' || $input.attr('type') === 'email') {
          $input.val('')
        } else if ( $input.attr('type') === 'radio' || $input.attr('type') === 'checkbox') {
          $input.prop('checked', false)
          $('div.order-form-items .order-form-items__item, .form-radio__item, .airline-checks').removeClass('active')
        }
      })

      $('.data-format-selectbox').find('.select-box__active-title').removeClass('focus').find('div.value').html('Dateiformat')
      $('.custom-select-box-station').find('.select-box__active-title').removeClass('focus').find('div.value').html('Station')
      $('.data-format-selectbox, .custom-select-box-station').find('.select-box__item').removeClass('selected')
      this.step = 1
      this.sidebar = 'order'
      this.setSidebarContent('order', 1, '')
      this.setSidebarContent('order', 2, '')
      this.setSidebarContent('order', 3, '')
      this.setStatusToSidebarElement('order', 3, false, false, true)
      this.setStatusToSidebarElement('order', 2, false, false, true)
      this.setStatusToSidebarElement('order', 1, false, false, true)
      this.router.navigate(`form/${this.step}/${this.sidebar}`)
    })
  }

  initSelectOrderItem() {
    const orderItems = `div.order-form-items .order-form-items__item, div.order-form-items .form-radio__item`
    $(document).on('click', orderItems, (el) => {
      const $el = $(el.currentTarget)
      const $clickedElementGroup = $(`div.order-form-items[data-step="${this.step}"]`)
      $clickedElementGroup.find('.order-form-items__item, .form-radio__item').removeClass('active')
      $clickedElementGroup.find('input[type="radio"]').prop('checked', false)

      $el.addClass('active')
      $el.find('input[type="radio"]').prop('checked', true)
      const $input = $el.find('input[type="radio"]')
      this.nextStep = $input.attr('data-next-step')

      this.stepScan.push({
        step: this.step,
        value: $input.val(),
        nextStep: this.nextStep,
        prevStep: this.prevStep
      })

      if ( this.step === '1') {
       this.selectedElementImage = $el.find('img').attr('src')
      }

      this.setStatusToSidebarElement(this.sidebar, this.sidebarStep, true, true)
      this.setSidebarContent(this.sidebar, this.sidebarStep, $input.val())
      this.checkButtons()
    })
  }

  setSidebarContent(meta, step, description) {
    const $sidebar = $(`div.sidebar[data-meta="${meta}"]`)
    const $sidebarStep = $sidebar.find(`.sidebar__step[data-step="${step}"]`)
    $sidebarStep.find('.sidebar__step-selection').html(description)
  }

  checkButtons() {
    const $forwardButton = $('button.forward')
    const $backbutton = $('button.back')
    const $sendButton = $('button.sendform')

    if ( this.sidebarStep !== '6') {
      if (this.nextStep.length > 0 || this.sidebarStep >= 3 ) {
        $forwardButton.addClass('active')
      } else {
        $forwardButton.removeClass('active')
      }

      if ( this.step !== '1' && (this.prevStep != 0 || this.lastSelectionForm != 0) ) {
        $backbutton.addClass('active')
      } else {
        $backbutton.removeClass('active')
      }

      if (this.sidebarStep === '6') {
        $sendButton.show()
        $forwardButton.hide();
      } else {
        $sendButton.hide()
        $forwardButton.show();
      }
    } else {
      $forwardButton.hide()
      $backbutton.hide()
    }
  }

  initNumberInput() {
    $('svg[data-minus-number').on('click', e => {
      let input = $(e.currentTarget).closest('.number-input').find('.required')
      let inputNumber = parseInt($(input)[0].value) ? parseInt($(input)[0].value) : parseInt(0)

      if ( parseInt(inputNumber - 1) >= 0) {
        inputNumber = inputNumber - 1
      }
      $(input)[0].value = inputNumber
    })

    $('svg[data-plus-number').on('click', e => {
      let input = $(e.currentTarget).closest('.number-input').find('.required')
      let inputNumber = parseInt($(input)[0].value) ? parseInt($(input)[0].value) : parseInt(0)
      inputNumber = inputNumber + 1
      $(input)[0].value = inputNumber
    })

    $('.input-number').on('keyUp', (e) => {
      var charCode = e.which || e.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
      if (e.shiftKey) return false;
      return true;
    })
  }

  initDropzoneUploadForDisplayStep32() {
    const args = {
      uploadUrl: '/upload.php',
      removeUrl: '/remove.php',
      initID: '#dropzone-uploader-display-step-32',
      maxFiles: 5,
      maxFilesize: 5,
      detailContainerDataField: 'display',
      env: 'eyecatcher',
      uploadType: 'display',
      userDir: this.userDir,
    }
    new FormDropzone(args)
  }

  initDropzoneUploadForDisplayStep31() {
    const args = {
      uploadUrl: '/upload.php',
      removeUrl: '/remove.php',
      initID: '#dropzone-uploader-display-step-31',
      maxFiles: 5,
      maxFilesize: 5,
      detailContainerDataField: 'display',
      env: 'eyecatcher',
      uploadType: 'display',
      userDir: this.userDir,
    }
    new FormDropzone(args)
  }

  initDropzoneUploadForFoilStep35() {
    const args = {
      uploadUrl: '/upload.php',
      removeUrl: '/remove.php',
      initID: '#dropzone-uploader-foil-step-35',
      maxFiles: 5,
      maxFilesize: 5,
      detailContainerDataField: 'foil',
      env: 'eyecatcher',
      uploadType: 'foil',
      userDir: this.userDir,
    }
    new FormDropzone(args)
  }

  initDropzoneUploadForTouchscreenStep33() {
    const args = {
      uploadUrl: '/upload.php',
      removeUrl: '/remove.php',
      initID: '#dropzone-uploader-touchscreen-step-33',
      maxFiles: 5,
      maxFilesize: 5,
      detailContainerDataField: 'touchscreen',
      env: 'eyecatcher',
      uploadType: 'touchscreen',
      userDir: this.userDir,
    }
    new FormDropzone(args)
  }

  initDropzoneUploadForTouchscreenStep32() {
    const args = {
      uploadUrl: '/upload.php',
      removeUrl: '/remove.php',
      initID: '#dropzone-uploader-touchscreen-step-32',
      maxFiles: 5,
      maxFilesize: 5,
      detailContainerDataField: 'touchscreen',
      env: 'eyecatcher',
      uploadType: 'touchscreen',
      userDir: this.userDir,
    }
    new FormDropzone(args)
  }

  initDropzoneUploadForFoilStep31() {
    const args = {
      uploadUrl: '/upload.php',
      removeUrl: '/remove.php',
      initID: '#dropzone-uploader-foil-step-31',
      maxFiles: 5,
      maxFilesize: 5,
      detailContainerDataField: 'foil',
      env: 'eyecatcher',
      uploadType: 'foil',
      userDir: this.userDir,
    }
    new FormDropzone(args)
  }

  initSelectBoxStation() {
    const openSelectBox = document.querySelectorAll('.select-box.custom-select-box-station span[data-open-selectbox]')
    const closeSelectBox = document.querySelectorAll('.select-box.custom-select-box-station span[data-close-selectbox]')
    const selectBoxItem = document.querySelectorAll('.custom-select-box-station .select-box__item')

    // Open Selectbox on Click
    openSelectBox.forEach(element => {
      element.addEventListener('click', () => {
        element.style.display = 'none'
        element.parentNode.classList.add('focus')
        element.parentNode.querySelector('.select-box span[data-close-selectbox]').style.display = 'block'
        element.parentNode.parentNode.querySelector('.select-box__item-container').style.display = 'block'
      })
    })

    // Close Selectbox on Click
    closeSelectBox.forEach(element => {
      element.addEventListener('click', () => {
        element.style.display = 'none'
        element.parentNode.classList.remove('focus')
        element.parentNode.querySelector('.select-box span[data-open-selectbox]').style.display = 'block'
        element.parentNode.parentNode.querySelector('.select-box__item-container').style.display = 'none'
      })
    })

    // Set Active item on Click and close item-container
    selectBoxItem.forEach(element => {
      element.addEventListener('click', () => {
        // Close item container, set default arrow svg
        element.parentNode.parentNode.querySelector('.select-box span[data-open-selectbox]').style.display = 'block'
        element.parentNode.parentNode.querySelector('.select-box span[data-close-selectbox]').style.display = 'none'
        element.parentNode.style.display = 'none'
        element.parentNode.classList.remove('focus')
        // Remove selected classes of all items
        element.parentNode.querySelectorAll('.select-box__item').forEach(item => {
          item.classList.remove('selected')
        })
        element.classList.add('selected') // Add selected class on clicked item

        // Replace clicked item
        const stationInput = element.querySelector('.value').innerText
        const elementParentNode = element.parentNode.parentNode
        elementParentNode.querySelector('.select-box__active-title').querySelector('.value').textContent = stationInput
        elementParentNode.querySelector('input').value = stationInput

        if (stationInput === 'Andere') {
          $('input.station-input').removeClass('disabled-for-station').addClass('required')
        } else {
          $('input.station-input').addClass('disabled-for-station').removeClass('required')
        }

        const inputName = elementParentNode.querySelector('input').name
        const inputValue = stationInput
        $(`input[name="${inputName}"].station-hidden-input`).val(inputValue)
      })
    })
  }

  initSelectBox() {
    const openSelectBox = document.querySelectorAll('.select-box.data-format-selectbox span[data-open-selectbox]')
    const closeSelectBox = document.querySelectorAll('.select-box.data-format-selectbox span[data-close-selectbox]')
    const selectBoxItem = document.querySelectorAll('.data-format-selectbox .select-box__item')

    // Open Selectbox on Click
    openSelectBox.forEach(element => {
      element.addEventListener('click', () => {
        element.style.display = 'none'
        element.parentNode.classList.add('focus')
        element.parentNode.querySelector('.select-box span[data-close-selectbox]').style.display = 'block'
        element.parentNode.parentNode.querySelector('.select-box__item-container').style.display = 'block'
      })
    })

    // Close Selectbox on Click
    closeSelectBox.forEach(element => {
      element.addEventListener('click', () => {
        element.style.display = 'none'
        element.parentNode.classList.remove('focus')
        element.parentNode.querySelector('.select-box span[data-open-selectbox]').style.display = 'block'
        element.parentNode.parentNode.querySelector('.select-box__item-container').style.display = 'none'
      })
    })

    // Set Active item on Click and close item-container
    selectBoxItem.forEach(element => {
      element.addEventListener('click', () => {
        // Close item container, set default arrow svg
        element.parentNode.parentNode.querySelector('.select-box span[data-open-selectbox]').style.display = 'block'
        element.parentNode.parentNode.querySelector('.select-box span[data-close-selectbox]').style.display = 'none'
        element.parentNode.style.display = 'none'
        element.parentNode.classList.remove('focus')
        // Remove selected classes of all items
        element.parentNode.querySelectorAll('.select-box__item').forEach(item => {
          item.classList.remove('selected')
        })
        element.classList.add('selected') // Add selected class on clicked item

        // Replace clicked item
        element.parentNode.parentNode
          .querySelector('.select-box__active-title')
          .querySelector('.value').textContent = element.querySelector('.value').innerText
        element.parentNode.parentNode.querySelector('input').value = element.querySelector('.value').innerText
        const inputName = element.parentNode.parentNode.querySelector('input').name
        const inputValue = element.querySelector('.value').innerText
        $(`input[name="${inputName}"]`).val(inputValue)
      })
    })
  }

  setMinDateForDatepicker() {
    const today = new Date();
    const numberOfDaysToAdd = 7;
    today.setDate(today.getDate() + numberOfDaysToAdd); // Add days to today
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    $('input[type="date"]').attr('min', `${yyyy}-${mm}-${dd}`)
  }

  gatherAndDisplayFormDataToOverview() {
    if (this.formData.length > 0) {

      const anderer_ansprechpartner = this.formData.filter(element => { return element.name === 'anderer_ansprechpartner'})
      const station = this.formData.filter(element => { return element.name === 'station_select'})[0].value
      $('.overview__image img').attr('src', this.selectedElementImage) // Set image from step 1 to order overview
      let formDataAnsprechpartnerCustomMapping

      if ( anderer_ansprechpartner.length <= 0 ) {
        formDataAnsprechpartnerCustomMapping = {
          ansprechpartner_email: this.formData.filter(element => { return element.name === 'email'})[0].value,
          ansprechpartner_name: this.formData.filter(element => { return element.name === 'name'})[0].value,
          ansprechpartner_phone_number: this.formData.filter(element => { return element.name === 'phone_number'})[0].value,
        }
      } else {
        formDataAnsprechpartnerCustomMapping = {
          ansprechpartner_email: '',
          ansprechpartner_name: '',
          ansprechpartner_phone_number: ''
        }
      }

      if (station === 'Andere') {
        formDataAnsprechpartnerCustomMapping = {
          andere_station: this.formData.filter(element => { return element.name === 'station'})[0].value
        }
      }

      let anmerkungen = this.formData.filter(element => { return element.name === 'additional_information_foil'})
      if ( anmerkungen.length > 0) {
        anmerkungen = anmerkungen[0].value
      }

      this.formData.forEach((item, key) => {
        let value = item.value
        if ( anderer_ansprechpartner.length <= 0) {
          if (item.name === 'ansprechpartner_email') {
            value = formDataAnsprechpartnerCustomMapping.ansprechpartner_email
          }
          if (item.name === 'ansprechpartner_phone_number') {
            value = formDataAnsprechpartnerCustomMapping.ansprechpartner_phone_number
          }
          if (item.name === 'ansprechpartner_name') {
            value = formDataAnsprechpartnerCustomMapping.ansprechpartner_name
          }
        }
        if (item.name === 'station_select' && station === 'Andere') {
          value = formDataAnsprechpartnerCustomMapping.andere_station
        }

        if ( item.name === 'format_width_foil' && item.value.length > 0) {
          $('.overview__order-data-details[data-type="foil"]').addClass('active')
        } else if(item.name === 'format_width_foil' && item.value.length <= 0) {
          $('.overview__order-data-details[data-type="foil"]').removeClass('active')
        }

        if ( item.name === 'format_width_display' && item.value.length > 0) {
          $('.overview__order-data-details[data-type="display"]').addClass('active')
        } else if(item.name === 'format_width_display' && item.value.length <= 0) {
          $('.overview__order-data-details[data-type="display"]').removeClass('active')
        }

        if ( item.name === 'format_width_touchscreen' && item.value.length > 0) {
          $('.overview__order-data-details[data-type="touchscreen"]').addClass('active')
        } else if(item.name === 'format_width_touchscreen' && item.value.length <= 0) {
          $('.overview__order-data-details[data-type="touchscreen"]').removeClass('active')
        }

        if ( item.name === 'lufthansa_standardfolie_anzahl' && item.value > 0) {
          $('.overview__order-data-details[data-type="lufthansa-standardfolie-anzahl"]').addClass('active')
          if ( anmerkungen.length > 0) {
            $('.overview__order-data-details[data-type="lufthansa-anmerkungen"]').addClass('active')
          } else if(item.name === 'additional_information_foil' && item.value.length <= 0) {
            $('.overview__order-data-details[data-type="lufthansa-anmerkungen"]').removeClass('active')
          }
        } else if(item.name === 'lufthansa_standardfolie_anzahl' && item.value <= 0) {
          $('.overview__order-data-details[data-type="lufthansa-standardfolie-anzahl"]').removeClass('active')
        }

        if ( item.name === 'lufthansa_sonderfolie_anzahl' && item.value > 0) {
          $('.overview__order-data-details[data-type="lufthansa-sonderfolie-anzahl"]').addClass('active')
          if ( anmerkungen.length > 0) {
            $('.overview__order-data-details[data-type="lufthansa-anmerkungen"]').addClass('active')
          } else if(item.name === 'additional_information_foil' && item.value.length <= 0) {
            $('.overview__order-data-details[data-type="lufthansa-anmerkungen"]').removeClass('active')
          }
        } else if(item.name === 'lufthansa_sonderfolie_anzahl' && item.value <= 0) {
          $('.overview__order-data-details[data-type="lufthansa-sonderfolie-anzahl"]').removeClass('active')
        }

        if ( item.name === 'lufthansa_standardfolie' && item.value.length > 0) {
          $('.overview__order-data-details[data-type="lufthansa-standardfolie-auswahl"]').addClass('active')
        } else if(item.name === 'lufthansa_standardfolie' && item.value.length <= 0) {
          $('.overview__order-data-details[data-type="lufthansa-standardfolie-auswahl"]').removeClass('active')
        }

        if (item.name.indexOf('airline[]') < 0  && item.name.indexOf('filename') < 0) {
          $(`.overview__details .overview-contact-items__item span[data-${item.name}], .overview__order span[data-${item.name}]`).html(value)
        }
      })
    }
  }
}

window.onload = () => {

  new SignaneForm
}
